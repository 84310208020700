/** @format */

"use client";
import React, { useEffect, useState } from "react";
import "./Footer.css";
import "../../app/media.css"; // Import global styles
import { apiService } from "@/services/api.services";
import LazyImage from "../../../src/app/LazyImage";
import Link from "next/link";
import { nav_links } from "@/Helper/content";
import instagram from "@/Helper/images/instagram.svg";
import message from "@/Helper/images/message.svg";
const Footer = () => {
  const [footerData, setFooterData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchFooterData() {
      try {
        const resp = await apiService.getAllUtilityData();
        if (resp.status === 200) {
          setFooterData(resp.data?.data?.attributes);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err);
      }
    }

    fetchFooterData();
  }, []);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <footer className="footer_area ">
      <div className="container">
        <div className=" footer_container ">
          {/* <div className=" footer_content_container">
            <div className="">
              <p className="footer_heading">Company</p>
              <div className="footer_nav_area">
                {nav_links.map((nav) => (
                  <p className="footer_text footer_link" key={nav.id}>
                    <Link
                      href={nav.href}
                      target={nav.id === 7 ? "_blank" : undefined}
                      rel={nav.id === 7 ? "noopener noreferrer" : undefined}
                    >
                      {nav.pathName}
                    </Link>
                  </p>
                ))}
              </div>
            </div>
            <div className="">
              <p className="footer_heading">Industries</p>
              <div className="footer_nav_area">
                <p className="footer_text " key={"digital"}>
                  <Link href={"#"}>
                    Healthcare
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link href={"#"}>
                    Education
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link href={"#"}>
                    QSR chains
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link href={"#"}>
                    Fitness
                  </Link>
                </p>
               
                <p className="footer_text " key={"digital"}>
                  <Link
                    href={
                      "#"
                    }
                  >
                    Transportation
                  </Link>
                </p>
              </div>
            </div>
            <div className="">
              <p className="footer_heading">Quick Links</p>
              <div className="footer_nav_area">
                <p className="footer_text " key={"Digital"}>
                  <Link href={"#"}>
                    Digital signage
                  </Link>{" "}
                  CMS
                </p>

                <p className="footer_text " key={"Privacy"}>
                  <Link href={"#"}>Privacy policy</Link>
                </p>
                <p className="footer_text " key={"Terms and condition"}>
                  <Link href={"#"}>Terms and condition</Link>
                </p>
              </div>
            </div>
          </div> */}
          <p>{footerData?.rights_reserved} Powered by <Link href="https://nextbrain.ca/" target="_blank">Nextbrain</Link></p> 
          <Link className="lefticons" href="mailto:juwan@standarddemo.ca">
            {" "}
            <LazyImage
              src={message.src}
              alt="Image of FULL DECONSTRUCTION services"
              className="lazy"
            />
          </Link>
          <Link
            className="lefticons"
            href="https://www.instagram.com/standarddemolition/"
          >
            <LazyImage
              src={instagram.src}
              alt="Image of FULL DECONSTRUCTION services"
              className="lazy"
            />
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
