/** @format */

"use client";
import CustomButton from "@/component/Buttons/CustomButton";
import React, { useState, useRef, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiService } from "@/services/api.services";
import ReCAPTCHA from "react-google-recaptcha";
import { useRouter } from "next/navigation";
import "./page.css";

const ContactForm = () => {
  const router = useRouter();
  const recaptchaRef = useRef(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
    recaptcha: Yup.boolean()
      .oneOf([true], "Field must be checked")
      .required("ReCAPTCHA is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      message: "",
      recaptcha: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        data: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          subject: values.subject,
          message: values.message,
        },
      };

      try {
        console.log("Submitted Values:", payload.data);

        const response = await apiService.postContactUsDetails(payload);
        if (response.status === 200) {
          apiService.sendThankyouMail({ data: payload.data });
          console.log("🚀 ~ onSubmit: ~ payload:", payload);
          setIsSuccess(true);
          setTimeout(() => {
            router.push("/thankyou");
          }, 900);
        }
        return response;
      } catch (error) {
        console.log("Form Submission Error:", error);
      }
    },
  });

  const onChange = (value) => {
    formik.setFieldValue("recaptcha", !!value);
    console.log("ReCAPTCHA Verified:", formik.values.recaptcha);
  };

  const sitekey = "6Lds1SUqAAAAALx9zdj_Jh_bL1FWjK6SnEcIB6p0";

  const memoizedIframe = useMemo(
    () => (
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.5743772329015!2d-79.32592382381857!3d43.74016797109821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cd8f8fe70335%3A0x6c85643e394bff6d!2s1315%20Lawrence%20Ave%20E%20%23510%2C%20North%20York%2C%20ON%20M3A%203R3%2C%20Canada!5e0!3m2!1sen!2sin!4v1723186033463!5m2!1sen!2sin"
          width="95%"
          height="308"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    ),
    []
  );

  return (
    <section className="ContactSection">
      <div className="container containerxxl">
        <div className="topsection">
          <h3>Contact us</h3>
          <p>
            For more information about our services, or to request a quote, give
            us a call! You can also fill<br/> out our contact form, and we will get
            back to you within one business day.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="LeftsideAddress">
              <h4>LOCATION</h4>
              <label>
                1315 Lawrence Avenue East Unit 511
                <br />
                Toronto, Ontario M3A 3R3
                <br />
                647-993-5911
              </label>
              {memoizedIframe}
            </div>
          </div>
          <div className="col-md-6">
            <div className="Letstalk">
              <div className="title_Section"></div>
              <form
                onSubmit={formik.handleSubmit}
                className="contactform_container"
              >
                <div className="contactform_container_row">
                  <TextInput
                    placeholder={"Enter your first name"}
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                  <TextInput
                    placeholder={"Enter your last name"}
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorText={formik.touched.lastName && formik.errors.lastName}
                  />
                </div>
                <div className="contactform_container_row">
                  <TextInput
                    placeholder={"Enter your email"}
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorText={formik.touched.email && formik.errors.email}
                  />
                  <TextInput
                    placeholder={"Enter your subject"}
                    id="subject"
                    name="subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorText={formik.touched.subject && formik.errors.subject}
                  />
                </div>
                <div className="contactform_container_row">
                  <TextInput
                    placeholder={"Write your message"}
                    id="message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorText={formik.touched.message && formik.errors.message}
                    multi={true}
                  />
                </div>
                <div className="contactform_container_row contactform_container_row_bottom">
                  <div className="contactform_recaptcha_container">
                    <ReCAPTCHA
                      sitekey={sitekey}
                      ref={recaptchaRef}
                      onChange={onChange}
                    />
                    {formik.touched.recaptcha && formik.errors.recaptcha && (
                      <p className="error-message">
                        {formik.errors.recaptcha ?? ""}
                      </p>
                    )}
                  </div>
                  <div className="submit_button">
                    <CustomButton
                      isLoading={formik.isSubmitting}
                      Text={isSuccess ? "Submitted" : "Submit"}
                      PrimaryColorBtn
                      type="submit"
                      style={{ minWidth: "175px" }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="min_height"></div>
    </section>
  );
};

const TextInput = ({
  errorText,
  label,
  placeholder,
  value,
  onChange,
  multi,
  ...props
}) => {
  if (multi) {
    return (
      <div className="input-container">
        <label htmlFor="input-field">{label}</label>
        <textarea
          {...props}
          type="text"
          id="input-field"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          cols="30"
          rows="8"
        />
        {errorText && <p className="error-message">{errorText}</p>}
      </div>
    );
  }
  return (
    <div className="input-container">
      <label htmlFor="input-field">{label}</label>
      <input
        {...props}
        type="text"
        id="input-field"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {errorText && <p className="error-message">{errorText}</p>}
    </div>
  );
};

export default ContactForm;
